// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {FacilityAccessibilityProduct} from "../product.facility/model/FacilityAccessibilityProduct";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.facilities-pilot-43425245.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-pilot-43425245.cloudfunctions.net',


  firebaseConfig: {
    apiKey: "AIzaSyBYdCAFP8Cfm_8DdZU363V_2SOsXGeQd00",
    appId: "1:540609943178:web:c84c7071fe0ec805c0bf73",
    authDomain: "facilities-pilot-43425245.firebaseapp.com",
    databaseURL: "https://facilities-pilot-43425245-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "540609943178",
    projectId: "facilities-pilot-43425245",
    storageBucket: "facilities-pilot-43425245.appspot.com",
  },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    // product: new FacilityAccessibilityProduct(),
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,
  },




};
