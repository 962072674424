import {BaseReference} from "../BaseReference";
import {IGuard} from "./Guard";
import {AnswerSet} from "../AnswerSet";



export class AndCondition extends BaseReference<IGuard[]> implements IGuard {


  isTrue(answers: AnswerSet): boolean {

    for( const guard of this.value ) {

      if( !guard.isTrue( answers )) {

        return false;
      }
    }

    return true;
  }

  protected onSetValue(value: IGuard[] | null) {
  }

  constructor( value: IGuard[] ) {

    super( value );
  }

}

