


export class BrowserAppEvaluationToolInfo {

  public version = '2023.9.14.2';

  constructor() {
  }

}
